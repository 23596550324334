import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);
const routes= [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/',
        component: () => import('../components/common/Home.vue'),
        meta: { title: '自述文件' },
        children: [
            {
                path: '/user',
                component: () => import('../components/page/Users.vue'),
                meta: { title: '用户管理' }
            },
            {
                path: '/permission',
                component: () => import('../components/page/Role.vue'),
                meta: { title: '权限管理' }
            },
            {
                path: '/news',
                component: () => import('../components/page/News.vue'),
                meta: { title: '新闻管理' }
            },
            {
                path: '/comment',
                component: () => import('../components/page/Comment.vue'),
                meta: { title: '评论管理' }
            },{
                path: '/live',
                component: () => import('../components/page/Live.vue'),
                meta: { title: '直播管理' }
            },
            {
                path: '/tabs',
                component: () => import('../components/page/Tabs.vue'),
                meta: { title: '栏目管理' }
            },
            {
                path: '/notice',
                component: () => import('../components/page/LiveNotice.vue'),
                meta: { title: '赛事预告' }
            },
            {
                path: '/suggest',
                component: () => import('../components/page/Suggest.vue'),
                meta: { title: '意见反馈' }
            },
            {
                path: '/push',
                component: () => import('../components/page/Push.vue'),
                meta: { title: '消息推送' }
            },
            {
                path: '/banner',
                component: () => import('../components/page/Banner.vue'),
                meta: { title: '轮播图' }
            },
            {
                path: '/riding',
                component: () => import('../components/page/riding.vue'),
                meta: { title: '骑行管理' }
            },
            {
                path: '/activity',
                component: () => import('../components/page/Activity/index.vue'),
                meta: { title: '活动管理' }
            },
            {
                path: '/activityEdit',
                name:"activityEdit",
                component: () => import('../components/page/Activity/activityEdit.vue'),
                meta: { title: '赛事编辑'}
            },
            {
                path: '/groupDetail',
                name:"groupDetail",
                component: () => import('../components/page/Activity/groupDetail.vue'),
                meta: { title: '组别详情'}
            },
            {
                path: '/registrationForm',
                name:'registrationForm',
                component: () => import('../components/page/Activity/registrationForm.vue'),
                meta: { title: '报名表单'}
            },
            {
                path: '/EnrollDetail',
                name: 'EnrollDetail',
                component: () => import('../components/page/Enroll/EnrollDetail.vue'),
                meta: { title: '报名详情'}
            },
            {
                path: '/enroll',
                name:"enroll",
                component: () => import('../components/page/Enroll/index.vue'),
                meta: { title: '报名管理' }
            },
            {
                path: '/medal',
                name:"medal",
                component: () => import('../components/page/competition/index.vue'),
                meta: { title: '奖章管理' }
            },
            {
                path: '/bannerManage',
                component: () => import('../components/page/pcManage/bannerManage/index.vue'),
                meta: { title: '轮播图管理' }
            },
            {
                path: '/liveManage',
                component: () => import('../components/page/pcManage/liveManage/index.vue'),
                meta: { title: '直播管理' }
            },
            {
                path: '/depthManage',
                component: () => import('../components/page/pcManage/depthManage/index.vue'),
                meta: { title: '深度聚焦管理' }
            },
            {
                path: '/photoManage',
                component: () => import('../components/page/pcManage/photoManage/index.vue'),
                meta: { title: '赛事精彩锦集管理' }
            },
            {
                path: '/photoListIndex',
                name:"photoListIndex",
                component: () => import('../components/page/pcManage/photoManage/photoListIndex.vue'),
                meta: { title: '图片列表' }
            }
            
        ]
    },
   
    {
        path: '/login',
        component: () => import('../components/page/Login.vue'),
        meta: { title: '登录' }
    }
];
const router = new VueRouter({
    // mode: 'history',
    routes
});

//钩子函数，拦截所有routes中的请求
router.beforeEach((to, from, next) => {
    // document.title = `${to.meta.title} | 后台管理系统`;
    //判断token
    if (localStorage.getItem('token')!=null || to.fullPath.endsWith('login')) {
        next()
    } else {
        // 没登录则跳转到登录界面
        next('/login')
    }
});
export default router;
