<template>
  <div class="parcel">
    <!-- 上传图片组件 -->
    <el-upload
      class="avatar-uploader parentLevel"
      :before-upload="beforeAvatarUpload"
      drag
      :action="'#'"
      :http-request="customUpload"
      :show-file-list="false"
      multiple
    >
      <i class="el-icon-plus avatar-uploader-icon" />
    </el-upload>

    <div
      v-for="(item, index) in slidesList"
      :key="index"
      class="parentLevel"
    >
      <el-image
        class="eleImg"
        :src="item"
        :preview-src-list="slidesList"
        @mouseenter="enter(index)"
        @error="errorFun(index)"
      />
      <div
        v-if="maskLayer == index"
        class="shade"
        @mouseleave="leave(index)"
      >
        <i
          v-if="slidesList.length > 1"
          class="el-icon-upload2 IconStyle"
          title="置顶"
          @click="stick(index)"
        />
        <i
          class="el-icon-zoom-in IconStyle"
          title="预览"
          @click="preview(index)"
        />
        <i
          class="el-icon-delete IconStyle"
          title="删除"
          @click="deleteIn(index)"
        />
      </div>
    </div>
    <!-- 大图预览 -->
    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="slidesList"
      :initial-index="imageIndex"
    />
    <!-- 裁剪图片 -->
    <vue-image-cropper-new
      ref="cropperImage"
      :dialog-visible.sync="dialogVisible"
      @uploadImgSuccess="clickSubmit"
    />

    <el-dialog
      title="编辑图片"
      :visible.sync="picModeDialogVisible"
      :show-close="false"
      :close-on-click-modal="false"
      append-to-body
    >
      <div style="margin-bottom: 20px;">图片格式：{{ imageType }}</div>
      <div
        style="margin-bottom: 20px;"
      >{{ oldImageTotalSize }} <span
        v-show="image_size > 1000"
        style="color:red;"
      >（图片大小已超过1M）</span></div>
      <!-- <div
        v-show="picMode == 2"
        style="margin-bottom: 20px;"
      >{{ cropOldImageTotalSize }} <span
        v-show="image_size*1 > 1000"
        style="color:red;"
      >（图片大小已超过1M）</span></div> -->
      <div style="margin-bottom: 20px;">
        <el-radio
          v-model="picMode"
          label="1"
        >原图</el-radio>
        <el-radio
          v-model="picMode"
          label="2"
        >图片裁剪</el-radio>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="small"
          @click="picModeCancel"
        >取 消</el-button>
        <el-button
          type="primary"
          size="small"
          @click="picModeConfirm"
        >确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import axios from '@/utils/request'

export default {
  name: 'UploadImgCropperNew',
  components: {
    ElImageViewer
  },
  model: {
    // 这个事件名可以随意写，它实际上是规定了子组件要更新父组件值需要注册的方法
    event: 'update'
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    value: {
      type: [String, Array]
    },
    max: {
      type: String,
      default: '1'
    },
    width: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      imageType: '',
      img: '',
      image_size: 0, // 图片大小
      dialogVisible: false, // 截图插件的弹窗
      picModeDialogVisible: false, // 图片上传模式弹窗
      picMode: '1',
      imageIndex: 0,
      showViewer: false,
      maskLayer: null,
      slidesList: [],
      imgBlobData: null // 图片blob数据
    }
  },
  computed: {
    oldImageTotalSize() {
      if (this.image_size < 1000) {
        return '图片大小：' + (this.image_size).toFixed(1) + 'KB'
      } else {
        return '图片大小：' + (this.image_size / 1000).toFixed(1) + 'MB'
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          // 回显数据
          if (typeof val === 'string') {
            this.slidesList = [val]
          } else {
            this.slidesList = val
          }
        } else {
          // 初始化
          if (this.max === 1) {
            this.slidesList = ''
          } else {
            this.slidesList = []
          }
        }
      }
    }
  },
  mounted() { },
  methods: {
    // 自定义上传函数
    customUpload(request) {
      this.picModeDialogVisible = true
      this.imgBlobData = request.file
    },
    /** 置顶 */
    stick(index) {
      const item = this.slidesList[index]
      this.slidesList.splice(index, 1)
      this.slidesList.unshift(item)
      if (this.max !== 1) {
        this.$emit('update', this.slidesList)
      }
    },
    /** 删除 */
    deleteIn(index) {
      this.slidesList.splice(index, 1)
      if (this.max === 1) {
        this.$emit('update', '')
      } else {
        this.$emit('update', this.slidesList)
      }
    },
    /** 预览 */
    preview(index) {
      this.showViewer = true
      this.imageIndex = index
    },
    closeViewer() {
      this.showViewer = false
    },
    /** 移入 */
    enter(index) {
      this.maskLayer = index
    },
    /** 移出 */
    leave(index) {
      this.maskLayer = null
    },
    // 上传文件之前
    beforeAvatarUpload(e) {
      console.log(e)
      this.imageType = e.type
      const image_size = e.size / 1000 // kb
      this.image_size = image_size
      console.log('image_size=' + image_size)

      if (this.slidesList.length >= this.max) {
        this.$message.error(`最多上传${this.max}张图片`)
        return false
      }
    },
    picModeConfirm() {
      // 原图上传
      if (this.picMode === '1') {
        const param = new FormData() // 创建form对象
        //   param.append('file', data) // 通过append向form对象添加数据
        param.append('file', this.imgBlobData, 'DX.jpg')
        axios.post('https://apichuanti.scleader.cn/chuanti-ucs/app-api/v1/files', param).then((res) => {
          this.picModeDialogVisible = false
          const img = res.data + '?imageMogr2/format/jpeg'
          if (this.max == 1) {
            console.log('图片地址2222'+img)
            this.$emit('update', img)
            this.slidesList = []
            this.$set(this.slidesList, 0, img)
          } else {
            console.log('图片地址')
            console.log(img)
            this.slidesList.push(img)
            this.$emit('update', this.slidesList)
          }
        }).catch((e) => {
          // failure('图片上传出错，请稍后重试')
          console.log('图片上传出错，请稍后重试')
        })
      } else { // 截图上传
        this.dialogVisible = true
        const data = window.URL.createObjectURL(this.imgBlobData)
        this.$refs.cropperImage.openData(data, { autoCropWidth: Number(this.width), autoCropHeight: Number(this.height) })
      }
    },
    picModeCancel() {
      this.picMode = '1'
      this.picModeDialogVisible = false
    },
    // 截图回调
    clickSubmit(imgUrl) {
      console.log('clickSubmit===' + imgUrl)
      // this.success(imgUrl)
      // this.$emit("cropperSubmit", imgUrl);
      // this.$emit('update', imgUrl)
      const img = imgUrl + '?imageMogr2/format/jpeg'
      if (this.max == 1) {
        this.$emit('update', img)
        this.slidesList = []
        this.$set(this.slidesList, 0, img)
      } else {
        console.log('图片地址')
        console.log(img)
        this.slidesList.push(img)
        this.$emit('update', this.slidesList)
      }
      this.picModeCancel()
    }
  }
}
</script>
<style lang="less" scoped>
.parcel {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
/deep/ .el-upload-dragger {
    width: 100px !important;
    height: 100px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
/deep/ .el-upload--text {
    border: 1px solid #fff;
    width: 101px;
    height: 101px;
}
// /deep/ .el-image-viewer__wrapper {
//     z-index: 2002 !important;
// } 
.el-image-viewer__wrapper {
  z-index: 9999 !important; /* 设置一个较高的 z-index 值 */
}
.imaPreview {
    width: 60px !important;
    height: 60px !important;
}
.avatar-uploader {
    display: flex;
    justify-content: center;
    align-items: center;
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.avatar {
    width: 178px;
    height: 178px;
    display: block;
}

.ImageList {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 20px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    width: 50%;
    height: 150px;
    margin-top: 20px;
}
.eleImg {
    width: 100px;
    height: 100px;
    border-radius: 6px;
    cursor: pointer;
    margin: 0px 10px;
    // z-index: 9999;
}
.shade {
    border-radius: 6px;
    margin: 0px 10px;
    z-index: 2;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: rgba(0, 0, 0, 0.6);
    // padding: 10px;
    cursor: pointer;
}
.parentLevel {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 120px !important;
}
.IconStyle {
    color: #ffffff;
    font-size: 20px;
}
/deep/ .el-image__preview {
  object-fit: contain;
  background-color: #f2f2f2;
}
</style>
<!-- <style lang="scss" scoped>
.parcel {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
::v-deep .el-upload-dragger {
  width: 100px !important;
  height: 100px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imaPreview {
  width: 60px !important;
  height: 60px !important;
}
.avatar-uploader {
  display: flex;
  justify-content: center;
  align-items: center;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.ImageList {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 20px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  width: 50%;
  height: 150px;
  margin-top: 20px;
}
.eleImg {
  width: 100px;
  height: 100px;
  border-radius: 6px;
  cursor: pointer;
  margin: 0px 10px;
  // object-fit:cover;
}
.shade {
  border-radius: 6px;
  margin: 0px 10px;
  z-index: 2;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  padding: 10px;
  cursor: pointer;
}
.parentLevel {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 120px !important;
}
.IconStyle {
  color: #ffffff;
  font-size: 20px;
}
::v-deep .el-image__preview {
  object-fit: contain;
  background-color: #f2f2f2;
}
</style> -->
